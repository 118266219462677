import React from 'react';
import { graphql } from 'gatsby';
import { InformationBox, InfoboxColumn, InfoboxSection } from './information-box';
import Link from '../elements/link';
import { formatNumber, stripUrlParkPrefix } from '../../functions/common';

export const InfoboxMarinePark = (query) => {
  const park = process.env.GATSBY_PARK,
    parkArea = `${formatNumber(query.field_park_area)} km<sup>2</sup>` || null;

  if (park.toLowerCase() !== 'amp') {
    return <React.Fragment key={query.id}></React.Fragment>;
  }

  const activitiesInMarineParks = query.field_activities_in_marine_parks.map(
    (item, index: Number) => {
      return (
        <li key={`${item.uri_alias}-${index}`}>
          {item.uri_alias ? (
            <Link to={stripUrlParkPrefix(item.uri_alias)}>{item.title}</Link>
          ) : item.title ? (
            item.title
          ) : (
            <React.Fragment key={`${query.id}-${index}`}></React.Fragment>
          )}
        </li>
      );
    }
  );

  const contactDetails = query.contact.map((item, index: Number) => {
    return item.uri_alias ? (
      <Link to={stripUrlParkPrefix(item.uri_alias)} key={index}>
        {item.title || `Contact ${index}`}
      </Link>
    ) : item.title ? (
      item.title && item.title
    ) : (
      <React.Fragment key={`${query.id}-${index}`}></React.Fragment>
    );
  });

  const hasMap = () => {
    return query.customDetailedMap?.customLocalFieldMediaFile?.publicURL ? true : false;
  };

  return (
    <InformationBox>
      {/* Column 1 */}
      <InfoboxColumn>
        {query.relationships.field_network?.name && (
          <InfoboxSection sectionTitle="Network">
            {/* TODO: This needs to link to the Network top level page somehow */}
            {query.relationships.field_network?.name}
          </InfoboxSection>
        )}
        {parkArea && (
          <InfoboxSection sectionTitle="Park area">
            <span dangerouslySetInnerHTML={{ __html: parkArea }} />
          </InfoboxSection>
        )}
        {query.field_depth_range && (
          <InfoboxSection sectionTitle="Depth range">{query.field_depth_range}</InfoboxSection>
        )}
        {query.field_average_depth && (
          <InfoboxSection sectionTitle="Average depth">{query.field_average_depth}</InfoboxSection>
        )}
      </InfoboxColumn>

      {/* Column 2 */}
      <InfoboxColumn>
        {hasMap() ? (
          <InfoboxSection sectionTitle="Detailed map">
            <Link to={query.customDetailedMap?.customLocalFieldMediaFile?.publicURL}>
              {query.field_detailed_map?.title}
            </Link>
          </InfoboxSection>
        ) : (
          query.field_detailed_map?.uri_alias &&
          query.field_detailed_map?.title && (
            <InfoboxSection sectionTitle="Detailed map">
              <Link to={stripUrlParkPrefix(query.field_detailed_map?.uri_alias)}>
                {query.field_detailed_map?.title}
              </Link>
            </InfoboxSection>
          )
        )}
        <InfoboxSection sectionTitle="Activities in marine parks">
          <ul>
            {activitiesInMarineParks && activitiesInMarineParks}
            <li>
              <Link to="https://onlineservices.environment.gov.au/parks/australian-marine-parks">
                Learn about permits and approvals
              </Link>
            </li>
          </ul>
        </InfoboxSection>
        {query.relationships?.field_media?.customLocalFieldMediaFile?.publicURL && (
          <InfoboxSection sectionTitle="Allowed activities">
            <Link to={query.relationships?.field_media?.customLocalFieldMediaFile?.publicURL}>
              View the activity table
            </Link>
          </InfoboxSection>
        )}
        {query.field_learn_more?.uri && (
          <InfoboxSection sectionTitle="Learn more">
            <Link to={query.field_learn_more?.uri}>{query.field_learn_more?.title}</Link>
          </InfoboxSection>
        )}
        {contactDetails && (
          <InfoboxSection sectionTitle="Contact us">{contactDetails}</InfoboxSection>
        )}
      </InfoboxColumn>
    </InformationBox>
  );
};

export const query = graphql`
  fragment InformationBoxMarinePark on paragraph__information_marine_park {
    id
    drupal_id
    internal {
      type
    }
    field_activities_in_marine_parks {
      title
      uri_alias
    }
    field_average_depth
    field_depth_range
    field_detailed_map {
      title
      uri
      uri_alias
    }
    field_learn_more {
      title
      uri
    }
    contact: field_link {
      title
      uri_alias
    }
    field_park_area
    paragraph_type {
      drupal_internal__target_id
    }
    customDetailedMap {
      id
      customLocalFieldMediaFile {
        publicURL
      }
    }
    relationships {
      field_media {
        name
        drupal_internal__mid
        customLocalFieldMediaFile {
          publicURL
        }
      }
      field_network {
        name
      }
      node__place {
        title
      }
    }
  }
`;
